<template>
  <div
    v-if="(form.showing && visible)"
    :id="`module-wrapper-${form.id}`"
    class="form-component"
    :class="{ 'active': active === form.id, visible: showForm(form.id) }"
  >
    <div class="form-title-container d-flex justify-content-between align-items-center">
      <div class="title-container" >
        <p class="form-title"
          v-if="(typeof editing === 'undefined' || (typeof editing !== 'undefined' && editing === false)) || !canEditCustomName"
          :class="{'editable': typeof editing !== 'undefined'}"
        >
          <span @click="editForm">
            {{ formName ?? form.name }}
            <Icon class="icon inline" tooltip="Alterar nome do exame personalizado" v-if="form.id.includes('exame-personalizado') && canEditCustomName">
              <Edit class="icon edit" v-if="form.id.includes('exame-personalizado')"/>
            </Icon>
          </span>
          <b-button
            v-if="historyButtonVisible"
            @click="openAttendanceHistory"
            class="open-history"
            variant="link"
            v-b-tooltip.hover title="Visualizar histórico"
          >
            <HistoryIcon />
          </b-button>

          <span v-if="additionalInformation" class="text-orange my-auto" v-b-tooltip="additionalInformationTooltip">
            <AlertIcon />
            {{ additionalInformation }}
          </span>

          <AttachFileButton
            v-if="attachModule"
            :module="attachModule"
          />
          <AttachSurgeryFiles
            v-if="surgeryId"
            v-b-tooltip.hover title="Adicionar arquivos da cirurgia"
            :surgeryId="surgeryId"
            :patientId="attendance?.attendance?.patient?.id"
          />
          <b-button class="btn-auto">
            <HasPreConsultation
              v-if="hasPreConsultationValue || hasPreConsultation && canEdit && checkAttendanceType || checkValueRefracao"
              @click="()=>$emit('getData', 'preConsultation')"
              v-b-tooltip.hover title="Trazer resultados da pré consulta"
            />
          </b-button>
          <b-button class="btn-auto">
            <HasPreConsultation
              v-if="hasExamValue || hasExam && canEdit"
              @click="()=>$emit('getData', 'exam')"
              v-b-tooltip.hover title="Trazer resultados do exame"/>
          </b-button>
        </p>
        <input
          v-else
          autocomplete="off"
          type="text"
          maxlength="60"
          class="form-title-input"
          placeholder="Nome do exame"
          v-model="form.name"
          @blur="saveFormEdition"
          :style="{ width: (form.name.length + 5) + 'ch', maxWidth: '80%' }"
          :class="{'invalid': !validName}"
        />
      </div>
      <div class="d-flex flex-row" @click="handleProps({ key: 'updated', value: true })">
        <p class="remove-form" @click="handleRemove" v-if="form.id !== 'cid'">remover</p>
        <ChevronDown class="chevron" v-b-toggle="`collapse-module-${form.id}`" />
      </div>
    </div>
    <b-collapse v-model="form.opened" :id="`collapse-module-${form.id}`">
      <div v-if="loading">
        <b-skeleton animation="fade" width="85%" />
        <b-skeleton animation="fade" width="70%" />
        <b-skeleton animation="fade" width="65%" />
      </div>
      <slot v-else></slot>
    </b-collapse>
  </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from 'vuex';

export default {
  props: {
    form: Object,
    formName: String,
    hideForm: Function,
    handleOpened: Function,
    editableName: Boolean,
    attachModule: String,
    hasPreConsultationValue: Boolean,
    hasPreConsultation: Boolean,
    hasExamValue: Boolean,
    hasExam: Boolean,
    surgeryId: String,
    checkValueRefracao: Boolean,
    additionalInformation: String,
    additionalInformationTooltip: String,
  },
  components: {
    ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
    HistoryIcon: () => import('@/assets/icons/history.svg'),
    AttachFileButton: () => import('@/components/Attendance/Forms/Components/AttachFileButton'),
    HasPreConsultation: () => import('@/assets/icons/hasPreConsultation.svg'),
    Edit: () => import('@/assets/icons/edit.svg'), 
    Icon: () => import('@/components/General/Icon'), 
    AttachSurgeryFiles: () => import('@/components/SurgeryCenter/General/AttachSurgeryFiles'),
    AlertIcon: () => import('@/assets/icons/alert-circle-orange.svg')
  },
  computed: {
    ...mapState({
      attendance: state => state.attendance,
      active: state => state.attendance.form.active,
      loading: state => state.attendance.loading,
      customFormEdit: state => state.attendance.customFormEdit,
      selectedForm: state => state.attendance.form.selected,
      history: state => state.attendance.history,
      canEdit: state => state.attendance.canEdit,
    }),
    ...mapGetters('attendance/menu', ['showForm']),
    historyButtonVisible() {
      return (
        !this.customFormEdit
        && (this.selectedForm?.label === 'Completa' || ['ATTENDANCE', 'EXAM'].includes(this.selectedForm?.type))
      )
    },
    canEditCustomName() {
      return (this.canEdit && (this.customFormEdit || !this.selectedForm?.forms?.find(f => f.id === this.form.id)) || this.$route.path.includes('configuracoes'))
    },
    checkAttendanceType() {
      const type = this.attendance?.attendance?.type
      return type === 'ATTENDANCE' || type === 'BV_PRESCRIPTION'
    }
  },
  mounted() {
    this.selectedForm?.type && this.handleVisibility(this.selectedForm?.type)

    setTimeout(() => {
      const el = document.getElementById(`module-wrapper-${this.form.id}`)
      el && (el.onclick = () => {
        this.handleActiveModule(this.form.id)
      })
    }, 500)
  },
  data() {
    return {
      editing: false,
      visible: false,
      validName: true,
    }
  },
  methods: {
    ...mapActions('attendance/history', ['handleHistoryVisibility', 'setModules']),
    ...mapActions('attendance/form', ['handleActiveModule', 'handleProps', 'updateForms']),
    editForm() {
      this.editableName && (this.editing = true)
    },
    saveFormEdition() {
      if(!this.form?.name?.length) return this.$toast.error('O nome do exame não pode ser vazio');

      this.editing = false
      this.$emit('onEditName')
    },
    openAttendanceHistory(){
      const payload = [{
        id: this.form.id,
        name: this.form.name
      }]

      if(this.form.id === 'pressao-intraocular'){
        payload.push({
          id: 'tonometria',
          name: 'Tonometria'
        })
      }
      this.setModules(payload)
      if(!this.history.opened)
        this.handleHistoryVisibility()
    },
    handleRemove () {
      this.hideForm()

      this.saveFormEdition()
      
      if (this.customFormEdit) {
        this.updateForms([{
          id: this.form.id,
          name: this.form.name,
          opened: this.form.opened,
          showing: this.form.showing,
          default_text: this.form.fields[0],
        }])
      }
    },
    handleVisibility(type){
      if( this.form?.scopes ){
        this.visible = !!this.form.scopes.find(el => {
          return el === type
        })
      } else { this.visible = true }
    }
  },
  watch: {
    'form.name': function (value) {
      this.validName = value?.length
    },
    selectedForm: {
      handler (value) {
        const type = value?.type ?? 'ATTENDANCE'
        this.handleVisibility(type)
      },
      deep: true
    }
  }
}
</script>
<style lang="scss" scoped>
  .form-component {
    padding: 4px 8px 4px 8px;
    margin: 8px 0;
    border-radius: 8px;
    transition: all 0.5s;
    flex: 1;

    &.active {
      background-color: var(--neutral-100);
    }
    &.visible {
      display: none;
    }

    .form-title-input {
      min-width: 35%;
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      color: var(--dark-blue);
      margin-bottom: 24px;
      border: 0;
      padding: 8px 16px;
      border-radius: 8px;
    }

    .remove-form {
      font-weight: 400;
      font-size: 12px;
      line-height: 24px;
      color: var(--states-error);
      cursor: pointer;
      margin-right: 14px;
    }

    .open-history {
      svg {
        transform: scale(0.8);
      }
    }

    .invalid {
      width: 20ch !important;
      border: 1px solid var(--states-error) !important;
    }
    .attach-file {
      svg {
        transform: scale(0.8);
      }
    }

    .chevron {
      width: 24px;
      height: 24px;
      stroke: var(--neutral-600);
      transition: all 0.5s;
      cursor: pointer;

      &.not-collapsed {
        animation: rotateUp .5s;
        -webkit-animation-fill-mode: forwards;
        -moz-animation-fill-mode: forwards;
        animation-fill-mode: forwards;
      }
      &.collapsed {
          animation: rotateDown .5s;
        -webkit-animation-fill-mode: forwards;
        -moz-animation-fill-mode: forwards;
        animation-fill-mode: forwards;
        /* transform: rotate(180deg); */
      }
      &.chevron:focus {
        outline: none !important;
      }
    }

    .btn-auto {
      color: #fff !important;
      border: none !important;
      background-color: transparent !important;
      padding: 10px 10px !important;
    }

    /* &.closed {
      padding: 0 16px;
      margin-bottom: 24px;

      .chevron {
        transform: rotate(180deg);
      }

      .form-title {
        margin-bottom: 0;
      }

      .fields-area {
        max-height: 0;
        overflow: hidden;
      }
    } */
    .icon {
      width: 20px;
      height: 20px;
      fill: var(--blue-500);
      cursor: pointer;
      &.edit {
        fill: none;
        stroke: var(--blue-500);
      }
      &.inline {
        display: inline-block !important;
      }
    }

    .text-orange {
      vertical-align: middle;
      color: #FF6B00;
    }
  }
</style>
